import { Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useProductTemplates } from '@/services/productTemplates';
import { Loader } from '@/components';
import { useLocationTypes } from '@/services/locationTypes';
import { GetProductTemplatesListResponse } from '@/api/productTemplates';
import { PlainCategoryLabelMap } from '@/enums';

const columns: ColumnsType<{
  type: 'category' | 'vertical';
  id: string;
  name: string;
}> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, { id, name, type }) => (
      <Space>
        {type === 'vertical' && '↳'}
        <Link to={`/product-templates/${id}`}>{name}</Link>
      </Space>
    )
  }
  // enable when we'll be ready
  // {
  //   title: 'Action',
  //   width: '20%',
  //   key: 'action',
  //   render: (_, { type }) => (
  //     <Space size="middle">
  //       {type === 'category' && <Button>Add vertical</Button>}
  //     </Space>
  //   )
  // }
];

const mapToColumns = (
  { data }: GetProductTemplatesListResponse,
  getVerticalLabel: (category: string) => string
): {
  type: 'category';
  id: string;
  name: string;
  children?: {
    type: 'vertical';
    id: string;
    name: string;
  }[];
}[] => {
  return data.map((category) => {
    return {
      type: 'category',
      id: category.id,
      name: PlainCategoryLabelMap[category.category],
      children:
        category?.children?.length === 0
          ? undefined
          : category.children?.map((vertical) => {
              return {
                type: 'vertical',
                id: vertical.id,
                name: getVerticalLabel(vertical.vertical)
              };
            })
    };
  });
};

export const ProductTemplates = () => {
  const { isLoading, data: productTemplates } = useProductTemplates();
  const { isLoading: isLoadingLocationTypes, data: locationTypes } =
    useLocationTypes();

  if (
    isLoading ||
    !productTemplates ||
    isLoadingLocationTypes ||
    !locationTypes
  ) {
    return <Loader />;
  }

  const tableData = mapToColumns(
    productTemplates,
    locationTypes.getVerticalLabel
  );

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={2}>Product Templates</Typography.Title>
        <Link to="/attributes">Edit attributes</Link>
      </div>
      <Table
        rowKey="id"
        expandable={{
          defaultExpandAllRows: true
        }}
        columns={columns}
        dataSource={tableData}
      />
    </div>
  );
};

import {
  SelfAssessmentValues,
  SelfAssessmentsResponse
} from '@/types/self-assessment';
import { api } from './api';

export const getSelfAssessments = (id: string) =>
  api.get<SelfAssessmentsResponse>(`/self-assessment/location/${id}`);

export const saveSelfAssessment = ({
  assesment,
  locationId
}: SaveSelfAssesmentPayload) =>
  api.post(`/self-assessment/location/${locationId}`, assesment);

export interface SaveSelfAssesmentPayload {
  assesment: Partial<SelfAssessmentValues>;
  locationId: string;
}

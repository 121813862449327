import { api } from './api';
import { camelToSnakeCase, snakeToCamelCase } from '@/utils';

export const getAttributesTranslations = async (
  language = 'de'
): Promise<{
  getAttributeLabel: (code: string) => string;
  getAttributeDescription: (code: string) => string | undefined;
}> => {
  const attributes: Record<string, { name: string; description?: string }> =
    await api.get(`/public/attributes?language=${language}`);

  return {
    /**
     * Returns the code as a fallback
     */
    getAttributeLabel(code: string): string {
      // FIXME: remove this when we will use snake case for data everywhere
      const label =
        attributes[snakeToCamelCase(code)]?.name ??
        attributes[camelToSnakeCase(code)]?.name;

      if (!label) {
        console.warn(`No attribute translation found for code: ${code}`);
      }

      return label ?? code;
    },

    getAttributeDescription(code: string): string | undefined {
      return attributes[code]?.description;
    }
  };
};

export interface AttributeDefinition {
  code: string;
  nameEN: string;
  nameDE: string;
  descriptionEN?: string;
  descriptionDE?: string;
}

interface AttributeDefinitionsApiResponse {
  data: AttributeDefinition[];
  meta: {
    totalItems: number;
    pageSize: number;
  };
}

export const getAttributesDefinitionsList = async (
  page: number
): Promise<AttributeDefinitionsApiResponse> =>
  api.get(`/admin/attributes-definitions?page=${page}`);

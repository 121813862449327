import { List, Modal, Space, Tag, Typography } from 'antd';
import { paths } from '@/routes';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useProductTemplateById } from '@/services/productTemplates';
import { Loader } from '@/components';
import { PlainCategoryLabelMap } from '@/enums';
import { useLocationTypes } from '@/services/locationTypes';
import { StarOutlined } from '@ant-design/icons';

const Content = ({
  attributes
}: {
  attributes: {
    code: string;
    is_inherited: boolean;
    is_highlighted: boolean;
  }[];
}) => {
  return (
    <>
      {/*
      <Typography.Title level={4}>Tax</Typography.Title>
      <Input value={productTemplate.tax} />
      */}
      <Typography.Title level={4}>Attributes</Typography.Title>
      <List
        bordered
        dataSource={attributes}
        renderItem={(item) => (
          <List.Item>
            <Space>
              {item.is_highlighted && <StarOutlined />}
              {item.code}
              {item.is_inherited && <Tag>inherited</Tag>}
            </Space>
          </List.Item>
        )}
      />
    </>
  );
};

export const ProductTemplateItem = () => {
  const history = useHistory();
  const { productTemplateId } = useParams<{ productTemplateId: string }>();
  const { isLoading, data: productTemplate } =
    useProductTemplateById(productTemplateId);
  const { data: locationTypes } = useLocationTypes();

  let subtitle = '';

  if (productTemplate) {
    subtitle += `: ${PlainCategoryLabelMap[productTemplate.category]}`;

    if (locationTypes && productTemplate.vertical) {
      subtitle += ` > ${locationTypes.getVerticalLabel(productTemplate.vertical)}`;
    }
  }

  return (
    <Modal
      title={`Edit template${subtitle}`}
      open={true}
      onCancel={() => history.push(paths.productTemplates)}
    >
      {isLoading && <Loader />}
      {!productTemplate && <div>Product template not found</div>}
      {productTemplate && <Content attributes={productTemplate.attributes} />}
    </Modal>
  );
};

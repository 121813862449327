import { useAttributesDefinitions } from '@/services/attributes';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { Loader } from '@/components';
import { usePagination } from '@/utils';

const columns: ColumnsType<{
  code: string;
  nameEN: string;
  nameDE: string;
  descriptionEN?: string;
  descriptionDE?: string;
}> = [
  {
    title: 'Code',
    dataIndex: 'code'
  },
  {
    title: 'Name (EN)',
    dataIndex: 'nameEN'
  },
  {
    title: 'Name (DE)',
    dataIndex: 'nameDE'
  },
  {
    title: 'Description (EN)',
    dataIndex: 'descriptionEN'
  },
  {
    title: 'Description (DE)',
    dataIndex: 'descriptionDE'
  }
];

export const Attributes = () => {
  const { currentPage, setPage } = usePagination();

  const { isLoading, data: attributesDefinitions } =
    useAttributesDefinitions(currentPage);

  if (isLoading || !attributesDefinitions) {
    return <Loader />;
  }

  return (
    <div>
      <h1>Attributes</h1>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={columns}
        dataSource={attributesDefinitions.data}
        pagination={{
          total: attributesDefinitions.meta.totalItems,
          pageSize: attributesDefinitions.meta.pageSize,
          current: currentPage,
          showSizeChanger: false
        }}
        onChange={(paginationChange) => {
          setPage(paginationChange.current ?? 1);
        }}
      />
    </div>
  );
};

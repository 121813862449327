import { useQuery, UseQueryOptions } from 'react-query';
import {
  getProductTemplateById,
  listProductTemplates,
  GetProductTemplatesListResponse,
  GetProductTemplateByIdApiResponse
} from '@/api/productTemplates';

const keys = {
  productTemplates: 'productTemplates'
};

export const useProductTemplates = (
  options: UseQueryOptions<
    GetProductTemplatesListResponse,
    unknown,
    GetProductTemplatesListResponse,
    string
  > = {}
) => useQuery(keys.productTemplates, () => listProductTemplates(), options);

export const useProductTemplatesCategory = (
  categoryId: string,
  options: UseQueryOptions<
    GetProductTemplatesListResponse,
    unknown,
    { vertical: string; id: string }[],
    string[]
  > = {}
) =>
  useQuery<
    GetProductTemplatesListResponse,
    unknown,
    { vertical: string; id: string }[],
    string[]
  >(
    [keys.productTemplates, 'byCategory', categoryId],
    () => {
      return listProductTemplates({ categoryId });
    },
    {
      ...options,
      select: ({ data }): { vertical: string; id: string }[] => {
        const template = data[0];

        if (!template) {
          return [];
        }

        const children = (template.children ?? []).map((child) => ({
          id: child.id,
          vertical: child.vertical
        }));

        return [
          {
            id: template.id,
            vertical: 'default'
          },
          ...children
        ];
      }
    }
  );

export const useProductTemplateById = (
  id: string,
  options: UseQueryOptions<
    GetProductTemplateByIdApiResponse,
    unknown,
    GetProductTemplateByIdApiResponse,
    string[]
  > = {}
) =>
  useQuery<
    GetProductTemplateByIdApiResponse,
    unknown,
    GetProductTemplateByIdApiResponse,
    string[]
  >([keys.productTemplates, id], () => getProductTemplateById(id), options);

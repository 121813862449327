import z from 'zod';
import { newApiClient } from '@/api/api';
import { validateAxiosResponse } from '@/utils/requestValidation';

const VerticalTemplateSchema = z.object({
  id: z.string(),
  vertical: z.string()
});

const CategoryTemplateSchema = z.object({
  id: z.string(),
  category: z.string(),
  children: z.array(VerticalTemplateSchema).optional()
});

export const GetProductTemplatesListResponseSchema = z.object({
  data: z.array(CategoryTemplateSchema)
});

export type GetProductTemplatesListResponse = z.infer<
  typeof GetProductTemplatesListResponseSchema
>;

const GetProductTemplateByIdApiResponseSchema = z.object({
  id: z.string(),
  tax: z.number().optional(),
  category: z.string(),
  vertical: z.string().nullable(),
  attributes: z.array(
    z.object({
      code: z.string(),
      is_inherited: z.boolean(),
      is_highlighted: z.boolean()
    })
  )
});

export type GetProductTemplateByIdApiResponse = z.infer<
  typeof GetProductTemplateByIdApiResponseSchema
>;

export const listProductTemplates = (
  query: {
    categoryId?: string;
  } = {}
): Promise<GetProductTemplatesListResponse> =>
  validateAxiosResponse(
    GetProductTemplatesListResponseSchema,
    newApiClient.get(`/admin/product-templates?${new URLSearchParams(query)}`)
  );

export const getProductTemplateById = (
  id: string
): Promise<GetProductTemplateByIdApiResponse> =>
  validateAxiosResponse(
    GetProductTemplateByIdApiResponseSchema,
    newApiClient.get(`/admin/product-templates/${id}`)
  );

import { useHistory, useLocation } from 'react-router-dom';

export function usePagination(): {
  currentPage: number;
  setPage: (page: number) => void;
} {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = parseInt(searchParams.get('page') ?? '1', 10);
  const history = useHistory();

  const setPage = (page: number) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('page', String(page));
    history.push(`${location.pathname}?${newSearchParams.toString()}`);
  };

  return {
    currentPage,
    setPage
  };
}
